import { CTAFeatured } from '@/app/_components/platform';
import { Footer as SharedFooter } from '@/app/components/shared';
import { Disclaimer, EligibilityClaim, Footnotes } from './';

import { Wrapper, Container } from './styles';

const Footer = ({
  bottomCTA,
  eligibilityClaim,
  disclaimer,
  footnotes,
  scrollTop,
  email,
  redirectToApp,
  referralUser,
  withoutClient,
}) => {
  return (
    <Wrapper>
      {bottomCTA && (
        <CTAFeatured
          id="cta-featured"
          content={bottomCTA}
          scrollTop={scrollTop}
          redirectToApp={redirectToApp}
          referralUser={referralUser}
          withoutClient={withoutClient}
        />
      )}
      <Container>
        <div>
          {eligibilityClaim && (
            <EligibilityClaim
              id="footer-eligibility-claim"
              content={eligibilityClaim}
              hasDisclaimer={!!disclaimer}
            />
          )}
          {disclaimer && (
            <Disclaimer
              id="footer-disclaimer"
              content={disclaimer}
              hasEligibilityClaim={!!eligibilityClaim}
            />
          )}
          {!!footnotes?.length && <Footnotes content={footnotes} />}
        </div>
        <SharedFooter
          email={email}
          extraPadding={!bottomCTA && !disclaimer && (footnotes?.length < 1 || !footnotes)}
        />
      </Container>
    </Wrapper>
  );
};
export default Footer;
