import { SectionHeader, LogoGrid } from '@swordhealth/ui-corporate';
import { StyledSection, StyledContainer } from './styles';

const ClinicalGrade = ({ content }) => {
  const {
    titles: { upperLabel, title, description },
    companies,
  } = content;

  const filteredCompanies = companies.data.map((company) => company.attributes);

  return (
    <StyledSection spacerTop colored lastColored>
      <StyledContainer>
        <SectionHeader
          description={description}
          label={upperLabel}
          title={title}
          titleSize="2xl"
          xAlign="center"
        />
        <LogoGrid logoList={filteredCompanies} />
      </StyledContainer>
    </StyledSection>
  );
};

export default ClinicalGrade;
