import { useContext } from 'react';

import EnrollButton from '@/app/components/core/EnrollButton';
import ClientContext from '@/contexts/client.context';
import { products } from '@/utils/products';

import { Container, SectionHeader } from '@swordhealth/ui-corporate';
import { Gradient, StyledCTA } from './styles';
import Section from '@/components/ui/Section';

//@Todo: in the future use this CTA instead.
// import { Cta } from '@swordhealth/ui-corporate';

const CTAFeatured = ({ content, id, scrollTop = false, referralUser }) => {
  const { client, openModal } = useContext(ClientContext);

  if (!content) {
    return null;
  }

  const { title, button } = content;

  return (
    <Gradient>
      <Section>
        <Container xAlign={'center'}>
          <StyledCTA>
            <SectionHeader title={title} xAlign={'center'} />
            <EnrollButton
              id={`${id}-enroll-button`}
              isActive={client.isActive}
              buttonLocation="footer"
              openModal={() => openModal({ buttonText: button?.label, buttonLocation: 'footer' })}
              client={client}
              buttonLabel={button?.label}
              scrollTop={scrollTop}
              product={products[client?.product?.key?.toUpperCase()]}
              redirectToApp={client?.redirectToApp}
              buttonApp={button}
              triage={client?.hasTriage}
              referralUser={referralUser}
            />
          </StyledCTA>
        </Container>
      </Section>
    </Gradient>
  );
};

export default CTAFeatured;
