import { Button, Container, SectionHeader } from '@swordhealth/ui-corporate';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

import { StyledSection, ButtonWrapper, Inner } from './styles';
import UnevenGrid from './UnevenGrid/UnevenGrid';

const UnevenGridSection = ({ content }) => {
  const {
    id,
    titles: { upperLabel, title, description },
    cards,
    buttonLabel,
  } = content;
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  return (
    <StyledSection colored>
      <Inner as="div">
        <Container>
          <SectionHeader
            title={title}
            label={upperLabel}
            description={description ?? ''}
            xAlign={isMobile ? 'center' : 'left'}
            titleSize={'2xl'}
          />
          <UnevenGrid sectionId={id} cards={cards} />
        </Container>
        <ButtonWrapper>
          <Button
            id={`uneven-grid-button-${id}`}
            variant="primary"
            size="lg"
            href={'/eligibility-check'}
            onClick={() => {
              trackButtonClicked({
                action: ACTIONS.REDIRECT_TO_COVERAGE,
                buttonLocation: 'uneven grid section',
                buttonText: buttonLabel ?? 'Get Started',
                buttonType: BUTTON_TYPES.NAVIGATION,
              });
            }}
          >
            {buttonLabel ?? 'Get Started'}
          </Button>
        </ButtonWrapper>
      </Inner>
    </StyledSection>
  );
};

export default UnevenGridSection;
