import { useContext } from 'react';
import NextImage from 'next/image';
import { useRouter } from 'next/navigation';

import ClientContext from '@/contexts/client.context';

import getImgSrcWorkaround from '@/utils/images';
import { products } from '@/utils/products';

import { Heading, Paragraph } from '@swordhealth/ui-corporate';
import { getClient } from '@/services/client.service';
import { compareDates } from '@/utils/dates';
import { getOnboardingURL } from '@/utils/onboarding-urls';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

import { Wrapper, Card, CardInner, LogoWrapper, Content, Image, Button } from './styles';

export default function Cards({ cards, selectProduct, client = null, country = null }) {
  const { setProduct } = useContext(ClientContext);
  const router = useRouter();

  const cardsWithoutImage = cards.filter(
    (card) => !getImgSrcWorkaround(card?.image?.url ?? card?.image?.data?.attributes.url),
  );

  const handleCardClick = async (item) => {
    if (!client) {
      selectProduct(products[item.solution.toUpperCase()]);
      setProduct(products[item.solution.toUpperCase()]);
      trackButtonClicked({
        action: ACTIONS.REDIRECT_TO_COVERAGE,
        buttonType: BUTTON_TYPES.NAVIGATION,
        buttonText: item?.button_label
          ? `${item?.button_label} - ${item.solution}`
          : `Get started - ${item.solution}`,
        destination: `/eligibility-check?solution=${item.solution}`,
        buttonLocation: 'card',
      });
      router.push(`/eligibility-check?solution=${item.solution}`);
    } else {
      const fetchedClient = await getClient({
        client: client,
        productKey: item?.solution,
      });

      if (fetchedClient && compareDates(fetchedClient?.launchDate)) {
        return router.push(`/waiting-list?client=${fetchedClient.clientKey}`);
      }
      const url = getOnboardingURL({
        client: fetchedClient,
        country: country,
        programType: products[item.solution.toUpperCase()].param,
      });

      trackButtonClicked({
        action: ACTIONS.REDIRECT_TO_ONBOARDING,
        buttonType: BUTTON_TYPES.NAVIGATION,
        buttonText: item?.button_label ?? `Get started - ${item.solution}`,
        destination: url,
        buttonLocation: 'form',
      });

      window.location.assign(url);
    }
  };

  return (
    <Wrapper>
      {cards.map((item) => (
        <Card
          key={item.solution}
          onClick={() => handleCardClick(item)}
          role="button"
          $white={!!client}
        >
          <CardInner $withoutImage={cardsWithoutImage.length > 0}>
            <div>
              <LogoWrapper>
                {item.logo?.data && (
                  <NextImage
                    src={getImgSrcWorkaround(item?.logo?.url ?? item?.logo?.data?.attributes.url)}
                    alt=""
                    height={24}
                    width={59}
                    priority
                  />
                )}
              </LogoWrapper>

              <Content>
                <Heading size="xl" as="h3">
                  {item.title}
                </Heading>
                {item.description && <Paragraph size="sm">{item.description}</Paragraph>}
              </Content>
              {cardsWithoutImage.length === 0 && item.image?.data && (
                <Image
                  src={getImgSrcWorkaround(item?.image?.url ?? item?.image?.data?.attributes.url)}
                  alt={item.title}
                  width={252}
                  height={604}
                  priority
                />
              )}
            </div>
            <div>
              <Button type="button" size="sm">
                {item?.button_label ?? 'Get started'}
                {cardsWithoutImage.length > 0 && (
                  <img src={'/assets/arrow_up.svg'} alt={'arrow_up'} />
                )}
              </Button>
            </div>
          </CardInner>
        </Card>
      ))}
    </Wrapper>
  );
}
