import { useContext } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/navigation';

import { Container } from '@/components/ui/flexbox';

import { Button } from '@swordhealth/ui-corporate';
import Logo from './Logo';
import { products } from '@/utils/products';
import ClientContext from '@/contexts/client.context';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

export default function Bar({ selectProduct }) {
  const { setProduct } = useContext(ClientContext);
  const router = useRouter();

  return (
    <Wrapper>
      <Container large>
        <WrapperInner>
          <div>
            <Logo />
          </div>
          <Buttons>
            <Button
              variant="secondary"
              size="sm"
              href={`${process.env.NEXT_PUBLIC_ONBOARDING_BASE_URL}`}
              onClick={() => {
                trackButtonClicked({
                  action: ACTIONS.REDIRECT_TO_SIGN_IN,
                  buttonLocation: 'header',
                  buttonText: 'Sign in here',
                  buttonType: BUTTON_TYPES.NAVIGATION,
                });
              }}
            >
              Login
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                selectProduct(products['PLATFORM']);
                setProduct(products.PLATFORM);
                trackButtonClicked({
                  action: ACTIONS.REDIRECT_TO_COVERAGE,
                  buttonType: BUTTON_TYPES.NAVIGATION,
                  buttonText: 'Get started',
                  destination: '/eligibility-check',
                  buttonLocation: 'bar',
                });
                router.push(`/eligibility-check`);
              }}
            >
              Get started
            </Button>
          </Buttons>
        </WrapperInner>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f7f4f2;
  z-index: 1000;
`;

const WrapperInner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 0;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    > *:nth-child(2) {
      display: none;
    }
    > *:first-child {
      padding: 9px 24px;
      height: 40px;
    }
  }
`;
